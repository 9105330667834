import _ from 'lodash';
import React from "react"
import { Card } from "antd";
import StatusTag from '../shared/StatusTag';
import PrArticle from './resources/PrArticle';
import SboOrder from './resources/SboOrder';
import SaatchiAccount from './resources/SaatchiAccount';
import LsaOrder from './resources/LsaOrder';
import GbpOptimization from './resources/GbpOptimization';
import WebsiteSetup from './resources/WebsiteSetup';
import SeoV2Order from './resources/SeoV2Order';
import AnswerBotAgentSetup from './resources/AnswerBotAgentSetup';
import AnswerBotPhoneSetup from './resources/AnswerBotPhoneSetup';
import AnswerBotPublishAgent from './resources/AnswerBotPublishAgent';
import AnswerBotNewActions from './resources/AnswerBotNewActions';
import AnswerBotCrawlRequest from './resources/AnswerBotCrawlRequest';
import AnswerBotGoogleCalendarIntegration from './resources/AnswerBotGoogleCalendarIntegration';
import AnswerBotKnowledgeFileUpload from './resources/AnswerBotKnowledgeFileUpload';
import WebsiteOptimization from './resources/WebsiteOptimization';
import AnswerBotKnowledgeFileDelete from './resources/AnswerBotKnowledgeFileDelete';
import DudaWebsiteDevelopment from './resources/DudaWebsiteDevelopment';
import AnswerBotDeletePhoneNumber from './resources/AnswerBotDeletePhoneNumber';
import AnswerBotAddCallTransferNumber from './resources/AnswerBotAddCallTransferNumber';
import AnswerBotDeleteCallTransferNumber from './resources/AnswerBotDeleteCallTransferNumber';

const OrderInfo = ({ order }) => (
  <Card title={<>{order.number} <StatusTag status={order.state} /></>}>
    {order.kind === 'pr_article' && <PrArticle resource={order.resource} />}
    {order.kind === 'sbo' && <SboOrder resource={order.resource} />}
    {order.kind === 'saatchi' && <SaatchiAccount resource={order.resource} />}
    {order.kind === 'lsa' && <LsaOrder resource={order.resource} />}
    {order.kind === 'gbp_optimization' && <GbpOptimization resource={order.resource} />}
    {order.kind === 'website_setup' && <WebsiteSetup resource={order.resource} />}
    {order.kind === 'website_optimization' && <WebsiteOptimization resource={order.resource} />}
    {order.kind === 'seo_v2' && <SeoV2Order resource={order.resource} />}
    {order.kind === 'answer_bot_agent_setup' && <AnswerBotAgentSetup resource={order.resource} />}
    {order.kind === 'answer_bot_phone_setup' && <AnswerBotPhoneSetup resource={order.external_payload} />}
    {order.kind === 'answer_bot_publish_agent' && <AnswerBotPublishAgent resource={order.external_payload} />}
    {order.kind === 'answer_bot_crawl_request' && <AnswerBotCrawlRequest resource={order.external_payload} />}
    {order.kind === 'answer_bot_new_actions' && <AnswerBotNewActions resource={order.external_payload} />}
    {order.kind === 'answer_bot_google_calendar_integration' && <AnswerBotGoogleCalendarIntegration resource={order.external_payload} />}
    {order.kind === 'answer_bot_knowledge_file_upload' && <AnswerBotKnowledgeFileUpload resource={order.external_payload} />}
    {order.kind === 'answer_bot_kb_file_removal' && <AnswerBotKnowledgeFileDelete resource={order.external_payload} />}
    {order.kind === 'website_development' && <DudaWebsiteDevelopment resource={order.resource} />}
    {order.kind === 'answer_bot_delete_phone_number' && <AnswerBotDeletePhoneNumber resource={order.external_payload} />}
    {order.kind === 'answer_bot_add_call_transfer_number' && <AnswerBotAddCallTransferNumber resource={order.external_payload} />}
    {order.kind === 'answer_bot_delete_call_transfer_number' && <AnswerBotDeleteCallTransferNumber resource={order.external_payload} />}
  </Card>
)

export default OrderInfo;
