import React from "react";
import { Descriptions, Typography } from "antd";
const { Title } = Typography;

const AnswerBotDeleteCallTransferNumber = ({ resource }) => {
  return (
    <>
      <Title level={4}>Order Information</Title>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Purpose">Delete call transfer number</Descriptions.Item>
        <Descriptions.Item label="Agent Name">{resource.agent_name}</Descriptions.Item>
        <Descriptions.Item label="Agent ID">{resource.agent_id}</Descriptions.Item>
        <Descriptions.Item label="Agent UUID">{resource.identifier}</Descriptions.Item>
        <Descriptions.Item label="Call Transfer Number">{resource.call_transfer_number}</Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default AnswerBotDeleteCallTransferNumber;
